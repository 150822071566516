<script setup lang="ts">
const props = withDefaults(defineProps<{
  user: GqlUser
  size?: 'small' | 'large'
  squircle?: boolean
}>(), {
  size: 'small',
  squircle: false,
})

const { t } = useI18n()

const avatarUrl = computed(() => props.user.profile?.avatar[props.size])
const initials = computed(() => (`${props.user.profile?.firstName[0]}${props.user.profile?.lastName[0]}`).toUpperCase())

const textSize = props.size === 'large' ? 'text-6xl' : 'text-lg'
const iconSize = props.size === 'large' ? 'size-20' : 'size-6'
</script>

<template>
  <div
    class="w-full h-full"
    :class="{ squircle: squircle }"
  >
    <div
      class="flex items-center justify-center bg-box-bg-light aspect-square"
      :class="{ squircle: squircle }"
    >
      <img
        v-if="avatarUrl"
        :src="avatarUrl"
        :alt="t('shared.components.avatar.title', { username: user.username })"
        :title="t('shared.components.avatar.title', { username: user.username })"
        class="object-cover"
      >
      <div
        v-else-if="user.profile && !user.profile.avatar[props.size]"
        class="font-normal text-box-text"
        :class="textSize"
      >
        {{ initials }}
      </div>
      <Icon
        v-else
        name="mingcute:user-3-line"
        :class="iconSize"
      />
    </div>
  </div>
</template>

<style scoped>
.squircle {
  clip-path: url(#squircle-clip-1);
}
</style>
